<template>
    <div class="w-full self-start">
        <ModalAddComment
            v-if="modalAddCommentVisible"
            :modalAddComment="modalAddComment"
            :employeeUuid="employeeUuid"
            @refresh="getAll"
            @close="modalAddComment = false"
            @closed="modalAddCommentVisible = false"
        />

        <div class="card flex justify-between items-center">
            <h1>{{ $t('employee_details.comments') }}</h1>
            <el-button type="primary" :loading="$wait.is('loading')" @click="showAddModal">
                {{ $t('common.add') }}
            </el-button>
        </div>

        <div v-for="comment in commentsData" :key="comment.uuid">
            <p class="ml-2 mb-1 mt-6 text-xs text-gray-500">
                {{ comment.created_at }}
            </p>
            <div class="card">
                <p class="mb-4 text-sm text-gray-600">
                    {{ comment.author }} {{ $t('employee_details.wrote') }}:
                </p>
                <el-button
                    class="absolute top-0 right-0 w-10 h-8 p-0 rounded flex justify-center items-center"
                    type="danger"
                    @click="handleDelete(comment.uuid)"
                >
                    <fa-icon :icon="['fas', 'trash']" />
                </el-button>
                <p>
                    {{ comment.content }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {
        ModalAddComment: () => import(/* webpackChunkName: "EmployeeComments/ModalAddComment" */ './ModalAddComment'),
    },

    data() {
        return {
            employeeUuid:           this.$route.params.uuid,
            commentsData:           [],
            modalAddComment:        false,
            modalAddCommentVisible: false,
        };
    },

    beforeCreate() {
        this.$emit('activeSubpage', 'comments');
    },

    created() {
        this.$wait.start('loading');
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading');

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${this.employeeUuid}/comments`);
            this.commentsData = data;

            this.$wait.end('loading');
        },

        async showAddModal() {
            this.modalAddCommentVisible = true;
            await new Promise(resolve => setTimeout(resolve, 50));
            this.modalAddComment = true;
        },

        handleDelete(uuid) {
            this.$confirm(this.$t('common.are_you_sure'), this.$t('common.warning'), {
                confirmButtonText: this.$t('common.yes'),
                cancelButtonText:  this.$t('common.cancel'),
                type:              'warning',
                beforeClose:       async (action, instance, done) => {
                    if (action === 'confirm') {
                        // @Filip
                        instance.confirmButtonLoading = true;
                        instance.closeOnClickModal = false;

                        try {
                            await axios.delete(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${uuid}/delete_comment`);
                            this.$notify.success({ title: 'Success' });
                            this.getAll();
                        } catch {
                            return;
                        }

                        done();
                        instance.confirmButtonLoading = false;
                        instance.closeOnClickModal = true;
                    } else {
                        done();
                    }
                },
            });
        },
    },
};
</script>
